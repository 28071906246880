input[type="email"],
input[type="text"],
input[type="number"],
input[type="password"],
textarea,
select {
  width:100%;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 8px;
  background-color: #fefefe;
  color: black!important;
  border: 1px solid var(--gray) !important;
}

:root{
  --green: #78be20;
  --gray: #a6aeb6;
  --red: #bc1c49;
  --pink: #f8c1d9;
  --lightgray: #f5f5f5;
  --lightgreen: #ddebcd;
}

.page {
  width:100%;
}


.sizelabel {
  padding-left: 20px;
}

.kokokuva{
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  overflow: hidden;
  margin: 0px;
}

#karttacontainer {
  width: 100%;
}

.kartta {
  width: inherit;
  box-shadow: 0px 0px 5px var(--gray);

  border-radius: 5px;
  border: 1px solid var(--gray);
}
#kartta {
  z-index: 0;
}

#kartta_alue3 {
  z-index: 100;
}


.radiolabel {
  font-size: 15px;
  font-weight: normal;
  width: 80%;
  display: inline-block;
  vertical-align: middle;
  color: black;
  margin: 0;
}

.radioIn {
  border-radius: 50%;
  border: 2px solid var(--gray);
  display: inline-block;
  vertical-align: middle;
  width: 1.5em;
  height: 1.5rem;
  margin: 1rem;
  border: 1px solid var(--gray);
}

.radioIn:checked {
  background-color: var(--green);;
  border-color: #f0f0f0;
}

.radioElement {
  border: 1px solid var(--gray);
  border-radius: 8px;
  margin: 0.5rem 0 0 0;
  padding: 1rem;
  box-shadow: 0px 0px 5px var(--gray);
}

body{
  background-color: white;
  font-family: Roboto Slab;
}

ul {
  padding-left: 30px;
}

label {
  margin-top: 1rem;
  font-weight: 700;
  font-size: 18px;
}

label, h1, h2, h3 {
   font-family: Montserrat;
   color: var(--green);
}

h1, h2, h3 {
  text-transform: uppercase;
}

::placeholder {
  color: var(--gray) !important;
}

label.checkbox {
  font-weight: normal;
}

button {
	color: white;
	border: 2px solid var(--green);
	border-radius: 5px;
	padding: 0.8rem 1rem;
  font-weight: bold;
	letter-spacing: 1px;
  margin: 1rem 0.3rem 0 0;
	font-size: 18px;
	background-color: var(--green);;
	cursor: pointer;
}


button:hover {
	color: var(--green);
	background-color: #ffffff;
}

button:disabled {
  background-color: var(--gray);
  border-color: var(--gray);
  color: var(--lightgray);
}

.error {
  color: var(--red);
  background-color: var(--pink);
}
.error::before {
  content: "* "
}


#logo {
  width:100%;
  max-width:500px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.info {
  width:100%;
  margin: 0.5rem 0;
}

.stronger {
  font-weight: bolder;
}

.oranger {
	color: var(--green);
}
