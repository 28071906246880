* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
}

html {
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, "avenir next", avenir,
    "helvetica neue", helvetica, ubuntu, roboto, noto, "segoe ui", arial,
    sans-serif;
  margin: 0;
  padding: 0;
  text-decoration-skip-ink: "auto";
}

.app {
  margin: calc((100vh / 25) * 0.6) calc((100vw / 25) * 0.6);
}

.hiililaskuri {
  padding: 15px;
  margin: 0px;
  /* background: linear-gradient(0deg, rgba(0,193,40,1) 0%, rgba(99,194,119,1) 25%, rgba(194,194,194,1) 100%); */
  background-image: url('/grass5.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-size: 100% 140%;
  /* background-image: url('/corn.jpeg');
  background-size: 100% 100%; */
  height: 100vh;
}

body {
  padding: 0;
  margin: 0;
  background-color: white;
  font-weight: 400;
  line-height: 1.563;
  color: var(--text-color);
  caret-color: var(--text-color);
}

/* Typography
––––––––––––––––––––––––––––––––– */

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 1rem;
  margin-top: 1em;
  font-weight: bold;
}

h1 {
  font-size: 3.052rem;
  letter-spacing: -0.15rem;
  line-height: 1;
}

h2 {
  font-size: 2.441rem;
  letter-spacing: -0.12rem;
  line-height: 1.2;
}

h3 {
  font-size: 1.953rem;
  letter-spacing: -0.09rem;
  line-height: 1.2;
}

h4 {
  font-size: 1.563rem;
  letter-spacing: -0.06rem;
  line-height: 1.3;
}

h5 {
  font-size: 1.25rem;
  letter-spacing: -0.03rem;
  line-height: 1.4;
}

h6 {
  font-size: 1rem;
  letter-spacing: 0;
  line-height: 1.5;
}

p {
  margin-bottom: 1.563rem;
}

p > *:last-child {
  margin-bottom: 0;
}

a {
  color: inherit;
  text-decoration: underline;
  text-decoration-color: var(--text-decoration-color);
  -webkit-text-decoration-color: var(--text-decoration-color);
  border-radius: 5px;
  text-underline-offset: 2px;
  text-decoration-thickness: 2px;
}

@media (hover: hover) {
  a:hover {
    text-decoration-color: var(--gray-800);
  }
}

a:focus-visible {
  box-shadow: 0 0 0 2px var(--focus-ring-color);
  outline: none;
}

small {
  font-size: 0.888rem;
}

hr {
  border: 1px solid var(--gray-700);
  margin: 3.052rem 0;
}

/* Form
––––––––––––––––––––––––––––––––– */
label {
  font-weight: bold;
  display: flex;
}

input[type="email"],
input[type="password"],
input[type="text"],
input[type="number"] {
  padding: 0.65rem 0.5rem;
  font-size: 1rem;
  border: 2px solid var(--gray-200);
  background-color: var(--gray-100);
  color: var(--gray-800);
  border-radius: 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

  input[type="email"]::placeholder,
  input[type="text"]::placeholder,
  input[type="number"]::placeholder,
  select,
  textarea {
    color: var(--gray-500);
  }


input[type="email"],
input[type="text"],
input[type="number"],
textarea,
select {
  width: 400px;
}

select {
  margin: 0;
  -webkit-appearance: none;
  box-sizing: border-box;
  padding: 0.65rem 0.5rem;
  font-size: 1rem;
  border: 2px solid var(--gray-200);
  border-radius: 10px;
  color: var(--gray-700);
  height: auto;
  background-color: var(--gray-100);
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 125.304 125.304"><path d="M62.652 103.895L0 21.41h125.304" fill="%23343334"/></svg>');
  background-repeat: no-repeat;
  background-size: 1rem;
  background-position: center right 0.5rem;
}

textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: 1rem;
  border: 2px solid var(--gray-200);
  color: var(--gray-700);
  border-radius: 10px;
  resize: vertical;
  background-color: var(--gray-100);
  box-sizing: border-box;
  padding: 0.65rem 0.5rem;
  font-family: -apple-system, BlinkMacSystemFont, "avenir next", avenir,
    "helvetica neue", helvetica, ubuntu, roboto, noto, "segoe ui", arial,
    sans-serif;
}

input:focus,
select:focus,
textarea:focus {
  outline: none;
  border: 2px solid var(--focus-ring-color);
}

input:invalid,
select:invalid,
textarea:invalid {
  border: 2px solid #ff7d87;
  box-shadow: none;
}

input[type="checkbox"] {
  margin: 0.4rem;
  height: 1rem;
  width: 1rem;
}

input[type="checkbox"]:hover {
  cursor: pointer;
}

input[type="checkbox"]:checked {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="78.369" height="78.369" viewBox="0 0 78.369 78.369"><path fill="white" d="M78.05 19.015l-48.592 48.59c-.428.43-1.12.43-1.548 0L.32 40.016c-.427-.426-.427-1.12 0-1.547l6.704-6.704c.428-.427 1.12-.427 1.548 0l20.113 20.112 41.113-41.113c.43-.427 1.12-.427 1.548 0l6.703 6.704c.427.427.427 1.12 0 1.548z"/></svg>');
  background-size: contain;
  accent-color: var(--green);
  background-color: white;
}



@media (hover: hover) {
  input[type="radio"]:hover {
    cursor: pointer;
  }
}

input[type="radio"]:checked {
  border: 2px solid var(--gray-700);
}

input[type="radio"]:focus-visible,
input[type="radio"]:checked:focus-visible {
  border-color: var(--focus-ring-color);
}


input[type="radio"]:checked::before {
  content: "";
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  background-color: var(--gray-700);
  align-self: center;
  border-radius: 50%;
}


input[type="submit"],
input[type="reset"],
input[type="button"],
button {
  padding: 0.5rem 1.25rem;
  font-size: 1rem;
  border-radius: 10px;
  background-color: var(--gray-700);
  border: 2px solid var(--gray-700);
  color: white;
  text-decoration: none;
  font-weight: bold;
  margin-bottom: 1rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  line-height: initial;
  transition: background-color 200ms ease-in-out, border 200ms ease-in-out,
    transform 200ms ease-in-out;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}
